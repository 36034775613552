
import { Component, Vue, Watch } from "vue-property-decorator";
import { UserService, GaodeService, WechatService } from "src/services";
import { Toast, Dialog } from "vant";
import { tools } from "src/utils";
import { DesSecretUtils } from "src/utils";
// import sensorsUtils from "src/utils/sensorsUtils"

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component
export default class verification extends Vue {
  /**
   * 获取验证码
   * @private
   * @returns boolean
   */
  private isShowGetCode: boolean = false;

  /**
   * 计时器
   * @private
   * @returns any
   */
  private timer: any = null;

  /**
   * 计时器 - 时间
   * @private
   * @returns number
   */
  private timerNum: number = 60;

  /**
   * 验证码值
   * @private
   * @returns phone
   */
  private smsCode: string = "";

  /**
   * 是否显示键盘
   * @private
   * @returns boolean
   */
  private showKeyboard: boolean = true;

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private get phone(): any {
    return (this.$route.params && this.$route.params.phone) || "";
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    // this.startTiming();
  }

  // 钩子
  protected mounted() {
    setTimeout(() => {
      document.getElementById("box").onclick = () => {
        this.focus();
      };
    }, 100);
  }

  /**
   * 开始计时
   * @private
   * @returns void
   */
  private startTiming(): void {
    this.isShowGetCode = false;
    this.timer = window.setInterval(() => {
      if (this.timerNum > 1) {
        this.timerNum--;
      } else {
        window.clearInterval(this.timer);
        this.isShowGetCode = true;
        this.timerNum = 60;
      }
    }, 1000);
  }

  /**
   * 获取验证码
   * @private
   * @returns void
   */
  private async onGetCode(): Promise<void> {
    try {
      await UserService.instance.smsSend({ phone: this.phone, smsType: 9 });
      Toast("验证码发送成功");
      this.startTiming();
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 组件离开之后
   * @private
   * @returns void
   */
  private beforeRouteLeave(to, from, next): void {
    if (to.path == "/home") {
      next();
      return;
    }
    Dialog.confirm({
      message: "短信可能有延迟，请再等一会儿",
      confirmButtonText: "再等一会儿",
      cancelButtonText: "返回",
    })
      .then(() => {})
      .catch(() => {
        window.clearInterval(this.timer);
        next();
      });
  }

  /**
   * 当路由发生变化的时候操作。
   * @protected
   * @param {any} - to 当前路由实例。
   * @returns {void}
   */
  @Watch("smsCode", { deep: true, immediate: true })
  protected changeSmsCode(newValue: any, oldValue: any): void {
    if (newValue.length > 6) {
      this.smsCode = newValue.substring(0, 6);
    } else if (newValue.length == 6) {
      this.onLogin();
    }
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private async onLogin(): Promise<void> {
    try {
      let { content: result } = await UserService.instance.smsLogin({
        code: localStorage.getItem("appid"),
        phone: this.phone,
        smsCode: this.smsCode,
      });
      if (result.data) {
        this.$store.dispatch("setUserInfo", result.data);
        // 绑定神策用户数据
        // sensorsUtils.bind("identity_Id",result.data.userId +"");
        this.$router.replace({ name: "home" });
      }
    } catch (err) {
      Toast(err);
    }
  }

  private msgLength: number = 0;

  /**
   * 当路由发生变化的时候操作。
   * @protected
   * @returns {void}
   */
  @Watch("msg", { deep: true, immediate: true })
  protected change(curVal: any): void {
    if (/[^\d]/g.test(curVal)) {
      this.smsCode = this.smsCode.replace(/[^\d]/g, "");
    } else {
      this.msgLength = curVal.length;
    }
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private focus(): void {
    (this.$refs.pwd as any).focus();
  }
}
